import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import { CollapseItem, getWindow, Menu } from '@web-passion/uikit';

import { LogoSvg } from '../Header/logo';
import TitleH6 from '../Title/h6';
import MenuLinks from '../Menu/links';

import './style.scss';

import loadComponents from '../Loadable';
import { MainColour } from '../../utils/variables';
import CookiesMessage from '../Cookies';
import { SidebarCaret } from '../Icons/icons';

const Container = loadComponents('container');
const Row = loadComponents('row');
const Col = loadComponents('column');
const Copyright = loadComponents('footer-copyright');

export default function Footer() {
  const { windowMD } = getWindow();

  const data = useStaticQuery(graphql`
    query FooterQuery {
      wp {
        ...SiteName
        footer {
          footerColumns {
            columns {
              header
              links {
                link
                label
                title
              }
            }
          }
          footerOptions {
            footerText
          }
        }
      }
    }
  `);

  const siteName = data.wp.generalSettings.title;
  const tagline = data.wp.generalSettings.description;

  return (
    <footer>
      <div
        style={{
          backgroundColor: MainColour,
          padding: windowMD ? `60px 0` : `45px 0`,
        }}
      >
        <Container>
          <Row rowGap={windowMD ? '45px' : `30px`} justify="center">
            <Col
              className="footerLogoCol"
              xxl={{ width: '200px' }}
              xl={{ width: '140px' }}
              style={{
                display: `flex`,
                alignSelf: `center`,
                // justifyContent: `center`,
              }}
            >
              <LogoSvg size="110px" />
            </Col>
            {data.wp.footer.footerColumns.columns.map((item, i) => {
              const key = `footerColumn${i}`;

              const colTitle = () => (
                <>
                  <TitleH6
                    margin={windowMD ? '15px' : '0px'}
                    colour="#fff"
                    style={{ width: `100%` }}
                  >
                    {item.header}
                  </TitleH6>
                  {!windowMD && (
                    <div
                      style={{
                        transform: `rotate(90deg)`,
                        marginLeft: `auto`,
                        marginRight: `15px`,
                      }}
                    >
                      <SidebarCaret size="8px" color="#fff" />
                    </div>
                  )}
                </>
              );

              const colMenu = () => (
                <Menu
                  id={`${key}_menu`}
                  className="footerMenu"
                  items={item.links}
                  customLink={item => <MenuLinks {...item} />}
                />
              );

              return (
                <Col
                  className="footerColumn"
                  key={key}
                  xxl={{ width: i === 0 ? `17%` : `16%` }}
                  xl={{ width: i === 0 ? `20%` : `19%` }}
                  lg={{ width: `30%` }}
                  md={{ width: `33.333%` }}
                >
                  {windowMD ? (
                    <>
                      {colTitle()}
                      {colMenu()}
                    </>
                  ) : (
                    <CollapseItem header={colTitle()}>{colMenu()}</CollapseItem>
                  )}
                </Col>
              );
            })}
            <Col
              className="footerBrandingCol"
              xs={{ width: `auto` }}
              style={{ alignSelf: `flex-end` }}
            >
              <div
                className="logoText"
                style={{
                  display: `flex`,
                  flexDirection: `column`,
                  gap: `10px`,
                }}
              >
                <strong>{siteName}</strong>
                <span>{tagline}</span>
              </div>
              <Copyright />
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ padding: `45px 0` }}>
        <Container>{parse(data.wp.footer.footerOptions.footerText)}</Container>
      </div>
      <CookiesMessage />
    </footer>
  );
}
